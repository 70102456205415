<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="$router.push({name: 'researcher.administration.residency-documentation.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'results'"
              :total="dataTotal"
              :fetch-path="'residencyDocumentation/fetch'"
              :filter-path="'residencyDocumentation/filter'"
              :export-path="'residencyDocumentation/export'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    v-if="loggedUser.roles.includes('super-admin')"
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="$router.push({name: 'researcher.administration.residency-documentation.edit', params: { id: field.id }})">
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    v-else
                    class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                    @click="$router.push({name: 'researcher.administration.residency-documentation.edit', params: { id: field.id }})"
                  ><i data-feather="eye" /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Stage</label>
                  <v-select
                    label="name"
                    :options="stages"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'residencyDocumentationStag/filter')"
                    @input="saveFilter($event, 'stage')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Type of authorization</label>
                  <v-select
                    label="name"
                    :options="authorizations"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'type')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Status</label>
                  <v-select
                    label="status"
                    :options="statuses"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'status')"
                  />
                </div>
                <p><strong>Issued on / since</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['begin_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['begin_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_to')"
                  />
                </div>
                <p><strong>Expire date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['end_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['end_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_date_to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Active</label>
                  <v-select
                    label="label"
                    :options="[
                      {label: 'Yes', name: 'Active', value: true},
                      {label: 'No', name: 'Inactive', value: false},
                      {label: 'All', name: 'All', value: 'all'},
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter($event, 'active')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '../../../partials/components/CompleteTable.vue'
import Vue from 'vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      filters: [],
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Type of authorization',
          checked: true,
          order: 2,
        },
        {
          name: 'Stage',
          checked: true,
          order: 3,
        },
        {
          name: 'Issued on/since',
          checked: true,
          order: 4,
        },
        {
          name: 'Expire date',
          checked: true,
          order: 5,
        },
        {
          name: 'Status',
          checked: true,
          order: 6,
        },
        {
          name: 'Permit',
          checked: true,
          order: 7,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        'Type of authorization': 'authorization.name',
        Stage: 'stage.name',
        'Issued on/since': 'begin_date',
        'Expire date': 'end_date_table',
        Status: 'status.status_table',
        Permit: 'files_.length',
      },
      title: 'Residency documentation',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'residencyDocumentation/items',
      dataTotal: 'residencyDocumentation/itemsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      authorizations: 'residencyDocumentationAuth/items',
      stages: 'residencyDocumentationStag/items',
      statuses: 'status/statuses',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'residency-documentation')
    await this.$store.dispatch('residencyDocumentationAuth/fetch')
    await this.$store.dispatch('residencyDocumentation/filter', this.$store.getters['filters/filters'])
    this.$store.dispatch('status/filterStatus', 'Residency Documentation')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'residency-documentation',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'residencyDocumentation', url: `${Vue.prototype.$groupUrl}/residency-documentation/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
